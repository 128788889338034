import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './global.css'; 
import './base.module.css';
import Feed from './components/feed/Feed.js';
import Online from './components/online/Online.js';
import Create from './components/create/Create.js';

const routes = [
  {
    key: 0,
    path: '/feed',
    elements: [<Feed key={0} />, <Online key={1} />]
  },
  {
    key: 1,
    path: '/create',
    elements: [<Create key={2} />]
  }
];

function App() {
  const routeComponents = routes.map((route) => (
    <Route
      key={route.key}
      path={route.path}
      element={route.elements} />
  ));

  return (
    <div 
      className="noselect">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {routeComponents}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;