import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  API_URL,
  AC_MANAGER,
  AC_IP,
  API_KEY,
  acServerApiHeader
} from './../../config';
import { Clipboard } from './../../extras';
import { acLauncher } from './../../acLauncher';
import styles from './Create.module.css';

function Create() {
  const [link, setLink] = useState(null);
  const [port, setPort] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [track, setTrack] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(0);
  const [carPack, setCarPack] = useState(0);
  const [carPacks, setCarPacks] = useState([]);
  const [selectedCarPack, setSelectedCarPack] = useState(0);

  async function launchServer() {
    try {
      const data = {
        track: track,
        carPack: carPack
      };

      let formdata = new formdata();
    formdata.append('track', track);
    formdata.append('carPack', carPack);

      const response = await axios.post(`${AC_MANAGER}api/server/create/`, formdata, acServerApiHeader);
      console.log('Send:', formdata);

      if (response.status === 200) {
        console.log('Data:', response.data);
        console.log('Send:', data);
        setPort(response.data[0].port);
        setLink(`acmanager://race/online/join?query=race/online/join&ip=${AC_IP}&httpport=`+response.data[0].port);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getTracks() {
    try {
      const config = {
        headers: {
          'Authorization': API_KEY,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios.get(`${API_URL}get/track/0/2023-10-17-12-04-30`);

      if (response.status === 200) {
        setTracks(response.data);
        //setLink('acmanager://race/online/join?query=race/online/join&ip=127.0.0.1&httpport='+response.data[0].port);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getCarPacks() {
    try {
      const config = {
        headers: {
          'Authorization': API_KEY,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios.get(`${API_URL}get/car-pack/0/2023-10-17-12-04-30`);

      if (response.status === 200) {
        setCarPacks(response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function pick(type, number) {
    if (type == 'carPack') {
      let foundKey = null;
      for (const key in carPacks) {
        if (carPacks[key].id === number) {
          foundKey = key;
          break;
        }
      }
      setSelectedCarPack(number);
      setCarPack(carPacks[foundKey]);
    }
    if (type == 'track') {
      let foundKey = null;
      for (const key in tracks) {
        if (tracks[key].id === number) {
          foundKey = key;
          break;
        }
      }
      setSelectedTrack(number);
      setTrack(tracks[foundKey]);
    }
  }

  useEffect(() => {
    getTracks();
    getCarPacks();
  }, [])
  
  return (
    <>
      <div className={styles.createContent}>
        {tracks.map((item, index) => (
          <input
            key={index}
            className={`${
              selectedTrack === item.id ? styles.active : null
            }`}
            type="button"
            value={item.name}
            onClick={() => pick('track', item.id)}/>
        ))}
        <br></br>
        {carPacks.map((item, index) => (
          <input
            key={index}
            className={`${
              selectedCarPack === item.id ? styles.active : null
            }`}
            type="button"
            value={item.name}
            onClick={() => pick('carPack', item.id)}/>
        ))}
        {link ? (
          <button
            onClick={() => {acLauncher(port)}}>
            Join now
          </button>
        ) : null}
        <input className={styles.btnLaunch} type="button" value="Launch" onClick={launchServer}/>
        <a className={styles.joinLink}>
          {link ? (
            <>
              {link}
              <button
                className={styles.joinLinkBtnCopy}
                onClick={() => {Clipboard(link)}}>
                <span className={`${styles.joinLinkBtnCopySpan} material-symbols-outlined`}>
                  content_copy
                </span>
              </button>
            </>
          ) : (null)}
          </a>
      </div>
    </>
  );
}

export default Create;