const env = 'prod';
let API_URL;
let AC_IP;
let AC_MANAGER;
let API_KEY;

if (env === 'prod') {
  API_URL = 'https://api.g8rage.com/';
  AC_IP = '46.242.130.106';
  AC_MANAGER = 'https://acmanager.g8rage.com/';
  API_KEY = '234';
} else {
  API_URL = 'http://localhost:8000/';
  AC_IP = '127.0.0.1';
  AC_MANAGER = 'http://127.0.0.1/';
  API_KEY = '234';
}

const acServerApiHeader = {
  headers: {
    'Authorization': API_KEY,
    //'Content-Type': 'application/json',
    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
  }
};

export {
  API_URL,
  AC_MANAGER,
  AC_IP,
  API_KEY,
  acServerApiHeader
};